<script lang="tsx">
import { defineComponentCoreUiBreadcrumbs } from '@core/app/components/core/ui/CoreUiBreadcrumbs.vue'
import { IconChevron, IconHouse } from '#components'

export default defineComponentCoreUiBreadcrumbs({
    props: {
        includeRoot: {
            default: true,
        },
        breadcrumbClass: {
            default: 'mon-hover-link',
        },
    },
    slots: {
        separator: () => <IconChevron width="9" rotate={-90} />,
        breadcrumb: ({ isRoot, item }) => <>{isRoot ? <IconHouse size="sm" class="text-black" aria-label={item ? item.label : item} /> : 'label' in item ? item.label : item}</>,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiBreadcrumbs" as *;

@include container {
    flex-wrap: wrap;
    gap: 1.625rem;
}

@include item {
    color: $mon-c-black;

    @include mon-text-extra-small;
    @include mon-font-light;
}

@include item--active {
    color: $mon-c-black;

    @include mon-font-regular;
}

@include separator {
    color: $mon-c-black;
}

@include collapsed {
    color: $mon-c-black;
}

</style>
