<template>
    <div :style="mainContentTopPadding">
        <slot name="override">
            <CoreContainer tag="div" class="pt-6">
                <BaseContainerContent :padding="{ vertical: 'none' }">
                    <UiBreadcrumbs
                        :collapse-max-items="4"
                        :items="page?.getBreadcrumbs() ?? breadcrumbs.items.value"
                    />
                </BaseContainerContent>
            </CoreContainer>

            <main id="main">
                <slot />

                <!--  ASSISTANT  -->
                <SectionAssistant />

                <!--  ADVANTAGES  -->
                <SectionAdvantages class="border-t-primary-200 border-t border-solid" />
            </main>
        </slot>
    </div>
</template>

<script lang="ts" setup>

const { syncedPage: page } = useCurrentPage()
const breadcrumbs = useBreadcrumbs()
const mainContentTopPadding = useStateMainContentTopPadding()

</script>
